import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
} from "@apollo/client";

const httpLink = createHttpLink({
    uri: "/graphql",
});

const graphClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: httpLink,
});

export default graphClient;
