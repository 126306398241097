import { Stack } from "@fluentui/react";
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router";
import { Link } from "react-router-dom";

import {
  AnswerExampleView,
  ExpertScoringView,
  FlatExpertScoringView,
  QuestionView,
  SearchExampleView,
  WeightTestingView
} from "./views";

import "./App.scss"

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <div id="App">
        <header>
          <Stack horizontal={true}>
            <div className="project-name">
              <span>Banshee</span>
            </div>
            <nav>
              <ul>
                <li>
                  <Link to="/weight-testing">
                    <span>Weight Testing</span>
                  </Link>
                </li>
                <li>
                  <Link to="/expert-scoring">
                    <span>Expert Scoring</span>
                  </Link>
                </li>
                <li>
                  <Link to="/flat-expert-scoring">
                    <span>Flat Expert Scoring</span>
                  </Link>
                </li>
                <li>
                  <Link to="/question">
                    <span>Question</span>
                  </Link>
                </li>
                <li>
                  <Link to="/search-example">
                    <span>Search Example</span>
                  </Link>
                </li>
                <li>
                  <Link to="/answer-example">
                    <span>Answer Example</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </Stack>
        </header>
        <div className="main-content">
          <Switch>
            <Route
              component={AnswerExampleView}
              exact={true}
              path="/answer-example"
            />
            <Route
              component={WeightTestingView}
              exact={true}
              path="/weight-testing"
            />
            <Route
              component={ExpertScoringView}
              exact={true}
              path="/expert-scoring"
            />
            <Route
              component={FlatExpertScoringView}
              exact={true}
              path="/flat-expert-scoring"
            />
            <Route
              component={QuestionView}
              exact={true}
              path="/question"
            />
            <Route 
              component={SearchExampleView}
              exact={true}
              path="/search-example"
            />
            <Redirect
              to="/weight-testing"
            />
          </Switch>
        </div>
      </div>
    );
  }
}
