import { ApolloProvider } from "@apollo/client";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import graphClient from "./graph-client";

import registerServiceWorker from "./registerServiceWorker";

import "./index.scss";

initializeIcons();

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") || undefined;
const rootElement = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <ApolloProvider client={graphClient}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  rootElement
);

registerServiceWorker();
