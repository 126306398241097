import { gql, useApolloClient,  } from "@apollo/client";
import { ITag, Label, TagPicker } from "@fluentui/react";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";

const queryText = gql`
query queryTenants($filter: String) {
    tenants(filter: $filter) {
        id, name
    }
}
`;

interface ITenantResult {
    tenants: Banshee.ITenant[];
}

interface IProps {
    onTenantSelected?(tenant: Banshee.ITenant | undefined): void;
}

const TenantSelector: FunctionComponent<IProps> = ({ onTenantSelected }) => {
    const [tenant, setTenant] = useState<Banshee.ITenant | undefined>();

    const graphClient = useApolloClient();

    const resolveSuggestions = useCallback(
        async (filter: string) => {
            const tenants = await graphClient.query<ITenantResult>({
                fetchPolicy: "no-cache",
                query: queryText,
                variables: { filter },
            });

            return tenants.data.tenants.map<ITag>(({id, name}) => ({
                key: id,
                name,
            }));
        },
        [graphClient],
    );

    const getTextFromItem = useCallback((item: ITag) => item.name, []);

    const tagsChanged = useCallback(
        (tags: ITag[] | undefined) => {
            if (!tags || !tags.length) {
                setTenant(undefined);
                return;
            }

            const tag = tags[0];
            const key = atob(tag.key.toString());

            setTenant((_) => ({
                id: parseInt(
                    key.substr(key.indexOf(":") + 1),
                    10,
                ),
                name: tag.name,
            }));
        },
        [setTenant],
    );

    useEffect(
        () => onTenantSelected && onTenantSelected(tenant), 
        [onTenantSelected, tenant],
    );

    const items = useMemo<ITag[]>(
        () => tenant ? [{ key: tenant.id, name: tenant.name }] : [],
        [tenant],
    );

    return (
        <div className="TenantSelector">
            <Label htmlFor="tenantSelector">
                Choose a tenant
            </Label>
            <TagPicker
                getTextFromItem={getTextFromItem}
                inputProps={{
                    id: "tenantSelector"
                }}
                itemLimit={1}
                onResolveSuggestions={resolveSuggestions}
                pickerCalloutProps={{ doNotLayer: true }}
                pickerSuggestionsProps={{
                    suggestionsHeaderText: "Suggested tenants",
                    noResultsFoundText: "No tenants found"
                }}
                selectedItems={items}
                removeButtonAriaLabel="Remove"
                selectionAriaLabel="Selected tenant"

                onChange={tagsChanged}
            />
        </div>
    );
};

export default TenantSelector;
