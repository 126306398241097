import { Dropdown, Icon, IDropdownOption, Persona, PersonaSize, PrimaryButton, Stack, Text } from "@fluentui/react";
import Axios, { AxiosError } from "axios";
import qs from "qs";
import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { debounceTime, Subject } from "rxjs";
import { TenantSelector } from "src/components";

const options: IDropdownOption[] = [
    { key: "All", text: "All" },
    { key: "B2B", text: "B2B" },
    { key: "B2C", text: "B2C" },
    { key: "Company", text: "Company" },
    { key: "Municipiality", text: "Municipiality" },
    { key: "OnlineRetailer", text: "Online Retailer" },
    { key: "PublicEnterprise", text: "Public Enterprise" },
    { key: "Retail", text: "Retail" }
];

const getTypeName = (type: number) => {
    switch (type) {
        case 1:
            return "User";
        case 2:
            return "Department";
        case 8:
            return "Office";
        case 9:
            return "Content Page";
        case 10:
            return "News Article";
        case 16:
            return "Wiki Article";
        case 44:
            return "Role";
        default:
            return "Unknown";
    }
};

interface IState {
    cohort: string;
    question: string;
    tenantId?: number;
    isLoading: boolean;
    answer?: Banshee.IAnswer;
}

const AnswerExampleView: FunctionComponent = () => {
    const [state, setState] = useState<IState>({
        cohort: "All",
        isLoading: false,
        question: "",
    });

    const cohortChange = useCallback(
        (_: any, option?: IDropdownOption): void => {
            const cohort = option?.key?.toString() || "All";

            setState((s) => ({ ...s, cohort }));
        },
        [setState],
    );

    const selectTenant = useCallback(
        (tenant: Banshee.ITenant | undefined) => {
            const tenantId = tenant?.id;

            setState((s) => ({ ...s, tenantId }));
        },
        [setState],
    );
    
    const doSearch = useCallback(
        async () => {
            try {
                setState((s) => ({ ...s, isLoading: true }));

                const response = await Axios.get<Banshee.IAnswer>(
                    "/api/v1/answer", {
                        params: {
                            question: state.question.trim(),
                            cohort: state.cohort,
                            tenantId: 1 // state.tenantId
                        },
                        paramsSerializer: (params: any) => qs.stringify(params),
                    }
                );

                setState((s) => ({
                    ...s,
                    answer: response.data,
                    isLoading: false,
                }));
            } catch (err) {
                setState((s) => ({ ...s, isLoading: false }));

                const error = err as AxiosError;

                if (error.code && parseInt(error.code, 10) > 499) {
                    console.log("Internal Error");
                }
            }
        },
        [setState, state.question, state.cohort, state.tenantId],
    );

    const onTextChange = useCallback(
        (event: ChangeEvent<HTMLTextAreaElement>) => {
            const { value } = event.target;

            setState((s) => ({ ...s, question: value }));
        },
        [setState],
    );

    return (
        <div id="AnswerExampleView">
            <Stack>
                <h1>Answer Example</h1>
                {/*
                <div className="prereqs ms-depth-16">
                    <Stack
                        horizontal={true}
                        tokens={{
                            childrenGap: 10
                        }}
                    >
                        <div className="cohort">
                            <Dropdown
                                label="Cohort"
                                multiSelect={false}
                                onChange={cohortChange}
                                options={options}
                                selectedKey={state.cohort}
                            />
                        </div>
                        <div className="tenant">
                            <TenantSelector
                                onTenantSelected={selectTenant}
                            />
                        </div>
                    </Stack>
                </div>
                */}
                <div className="background-wrapper">
                    <div className="post-wrapper">
                        <div className="header">
                            <Persona
                                text="Kalle Andersson"
                                size={PersonaSize.size32}
                            />
                        </div>
                        <div className="input">
                            <textarea
                                onChange={onTextChange}
                                value={state.question}
                            />
                        </div>
                        <div className="actions">
                            <div className="dots">
                                <Icon iconName="More" />
                            </div>
                            <div className="help">
                                <Icon
                                    iconName="InfoSolid"
                                />
                            </div>
                            <PrimaryButton className="button" onClick={doSearch}>
                                Skapa inlägg
                            </PrimaryButton>
                        </div>
                        <div className="suggestions">
                            <div className="content">
                                <ul>
                                    {state.answer?.documents?.slice(0, 2)?.map((d) => (
                                        <li key={"doc" + d.systemId}>
                                            <div className="title">
                                                <Text>
                                                    {d.name}
                                                </Text>
                                            </div>
                                            <div className="excerpt">
                                                Det här är ett utdrag ur artikeln
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="people">
                                <ul>
                                    {state.answer?.experts?.slice(0, 1)?.map((e) => (
                                        <li key={"expert" + e.systemId}>
                                            <Persona
                                                text={e.name}
                                                size={PersonaSize.size24}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Stack>
        </div>
    );
};

export default AnswerExampleView;
