import { DetailsList, PrimaryButton, SelectionMode, Slider, Spinner, SpinnerSize, Stack, Text, TextField } from "@fluentui/react";
import Axios, { AxiosError } from "axios";
import React, { FunctionComponent, useCallback, useMemo, useState } from "react";

import { TenantSelector } from "src/components";

interface IState {
    tenantId: number | undefined;
    titleBudget: number;
    tagBudget: number;
    headingBudget: number;
    paragraphBudget: number;
    depthDampening: number;
    normalizedValue: string;
    isLoading: boolean;
    terms?: IExpertScoring[],
}

interface IScoreBreakdown {
    sum: number;
    titles: number;
    tags: number;
    headings: number;
    paragraphs: number;
}

interface ISourceOccurance {
    name: string;
    type: number;
    score: number;
}

interface IExpertScoring {
    type: number;
    name: string;
    score: IScoreBreakdown;
    occurances: ISourceOccurance[];
}

const FlatExpertScoringView: FunctionComponent = () => {
    const [state, setState] = useState<IState>({
        tenantId: undefined,
        titleBudget: 30,
        tagBudget: 20,
        headingBudget: 15,
        paragraphBudget: 20,
        depthDampening: 80,
        normalizedValue: "",
        isLoading: false,
    });

    const selectTenant = useCallback(
        (tenant: Banshee.ITenant | undefined) => {
            console.log(tenant);

            setState((s) => ({
                ...s,
                tenantId: tenant?.id,
            }));
        },
        [setState],
    );

    const { isLoading } = state;
    const onSubmit = useCallback(
        async () => {
            if (state.isLoading) {
                console.log("still loading - exiting...");
                return;
            }

            setState((s) => ({
                ...s,
                isLoading: true,
            }));

            if (!state?.normalizedValue) {
                console.log("normalizedValue was not set - exiting...");
                setState((s) => ({ ...s, isLoading: false}));
                return;
            }

            const params: any = { ...state };
            params.normalizedValue = params.normalizedValue.toLowerCase();

            if (isNaN(params.tenantId)) {
                delete params.tenantId;
            }

            delete params.isLoading;
            delete params.terms;
            params.depthDampening /= 100;

            try {
                const response = await Axios.get<IExpertScoring[]>(
                    "/api/v1/flatexperts", {
                        params,
                        headers: {
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        },
                    },
                );

                setState((s) =>({
                    ...s,
                    terms: response.data,
                    isLoading: false,
                }));
            } catch (err) {
                const axiosErr = err as AxiosError;

                setState((s) =>({
                    ...s,
                    terms: [],
                    isLoading: false,
                }));

                const code = parseInt(axiosErr.code || "0", 10);
                if (code > 499) {
                    alert("Internal Server Error!");
                }
            }
        },
        [setState, state],
    )

    const onTitleBudgetChange = useCallback(
        (value) => setState((s) => ({ ...s, titleBudget: value })),
        [setState],
    );

    const onTagBudgetChange = useCallback(
        (value) => setState((s) => ({ ...s, tagBudget: value })),
        [setState],
    );

    const onHeadingBudgetChange = useCallback(
        (value) => setState((s) => ({ ...s, headingBudget: value })),
        [setState],
    );

    const onParagraphBudgetChange = useCallback(
        (value) => setState((s) => ({ ...s, paragraphBudget: value })),
        [setState],
    );

    const onDepthDampeningChange = useCallback(
        (value) => setState((s) => ({ ...s, depthDampening: value })),
        [setState],
    );

    const onNormalizedValueChange = useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { value } = event.target as HTMLInputElement;

            setState((s) => ({ ...s, normalizedValue: value }));
        },
        [setState],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const terms = state.terms || [];

    const items = useMemo(
        () => {
            if (!terms || !terms.length) {
                return [];
            }

            const groups = terms.reduce<{ [type: number]: any[] }>(
                (acc, row)  => {
                    if (!acc[row.type]) {
                        acc[row.type] = [];
                    }

                    acc[row.type].push(row);

                    return acc;
                },
                {},
            );

            Object.keys(groups)
                .filter(k => !isNaN(+k))
                .map(k => parseInt(k))
                .forEach(type => {
                    groups[type] = groups[type]
                        .sort(
                            (a, b) => b.score.sum - a.score.sum,
                        )
                        .map(
                            (row) => `${row.name} (${Math.round(row.score.sum)})`
                        );
                });

            const len = Object.keys(groups)
                .filter(k => !isNaN(+k))
                .map(k => groups[parseInt(k)]?.length || 0)
                .sort((a, b) => b - a)[0];

            return [...Array(len)].map((_: undefined, i) => ({
                User: (groups[1] || [])[i],
                Department: (groups[2] || [])[i],
                Office: (groups[8] || [])[i],
                Role: (groups[44] || [])[i]
            }));
        },
        [terms]
    );

    return (
        <div id="FlatExpertScoringView">
            <h1>Flat Expert Scoring</h1>
            <Stack horizontal={true}>
                <div className="controls ms-depth-16">
                    <TenantSelector onTenantSelected={selectTenant} />
                    <div className="sliders">
                        <Slider
                            label="Title budget"
                            max={100}
                            min={0}
                            onChange={onTitleBudgetChange}
                            value={state.titleBudget}
                            showValue={true}
                        />
                        <Slider
                            label="Tags budget"
                            max={100}
                            min={0}
                            onChange={onTagBudgetChange}
                            value={state.tagBudget}
                            showValue={true}
                        />
                        <Slider
                            label="Heading budget"
                            max={100}
                            min={0}
                            value={state.headingBudget}
                            onChange={onHeadingBudgetChange}
                            showValue={true}
                        />
                        <Slider
                            label="Paragraph budget"
                            max={100}
                            min={0}
                            value={state.paragraphBudget}
                            onChange={onParagraphBudgetChange}
                            showValue={true}
                        />
                        <Slider
                            label="Depth Dampening"
                            max={100}
                            min={0}
                            value={state.depthDampening}
                            onChange={onDepthDampeningChange}
                            showValue={true}
                            valueFormat={(value) => `${value}%`}
                        />
                    </div>
                </div>
                <div className="result">
                    <Stack>
                        <div className="input">
                            <Stack
                                horizontal={true}
                                verticalAlign="end"
                                tokens={{
                                    childrenGap: 10
                                }}
                            >
                                <TextField
                                    name="normalizedValue"
                                    label="Normalized Term"
                                    ariaLabel="Enter a term"
                                    onChange={onNormalizedValueChange}
                                    value={state.normalizedValue}
                                />
                                <PrimaryButton
                                    onClick={onSubmit}
                                >
                                    Run
                                </PrimaryButton>
                            </Stack>
                        </div>
                        <div className="output">
                            {isLoading && <Spinner size={SpinnerSize.large} />}
                            {!isLoading && items.length > 0 && (
                                <DetailsList
                                    items={items}
                                    selectionMode={SelectionMode.none}
                                />
                            )}
                            {!isLoading && items.length === 0 && (
                                <Text>No entries to display...</Text>
                            )}
                        </div>
                    </Stack>
                </div>
            </Stack>
        </div>
    );
};

export default FlatExpertScoringView;
