import {
    Dropdown,
    IDropdownOption,
    PrimaryButton,
    Spinner,
    Stack,
    Text,
    TextField
} from "@fluentui/react";
import Axios, { AxiosError } from "axios";
import React, { FunctionComponent, useCallback, useState } from "react";
import { TenantSelector } from "src/components";

const options: IDropdownOption[] = [
    { key: "All", text: "All" },
    { key: "B2B", text: "B2B" },
    { key: "B2C", text: "B2C" },
    { key: "Company", text: "Company" },
    { key: "Municipiality", text: "Municipiality" },
    { key: "OnlineRetailer", text: "Online Retailer" },
    { key: "PublicEnterprise", text: "Public Enterprise" },
    { key: "Retail", text: "Retail" }
];

const getTypeName = (type: number) => {
    switch (type) {
        case 1:
            return "User";
        case 2:
            return "Department";
        case 8:
            return "Office";
        case 9:
            return "Content Page";
        case 10:
            return "News Article";
        case 16:
            return "Wiki Article";
        case 44:
            return "Role";
        default:
            return "Unknown";
    }
};

interface IState {
    cohort: string;
    question: string;
    tenantId?: number;
    isLoading: boolean;
    answer?: Banshee.IAnswer;
}

const QuestionView: FunctionComponent = () => {
    const [state, setState] = useState<IState>({
        cohort: "All",
        isLoading: false,
        question: "",
    });

    const cohortChange = useCallback(
        (_: any, option?: IDropdownOption): void => {
            const cohort = option?.key?.toString() || "All";

            setState((s) => ({ ...s, cohort }));
        },
        [setState],
    );

    const selectTenant = useCallback(
        (tenant: Banshee.ITenant | undefined) => {
            const tenantId = tenant?.id;

            setState((s) => ({ ...s, tenantId }));
        },
        [setState],
    );

    const questionChange = useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { value: question } = event.target as HTMLInputElement | HTMLTextAreaElement;

            setState((s) => ({ ...s, question }));
        },
        [setState],
    );

    const submitQuestion = useCallback(
        async () => {
            if (state.isLoading) {
                return;
            }

            try {
                setState((s) => ({ ...s, isLoading: true }));

                const response = await Axios.get<Banshee.IAnswer>(
                    "/api/v1/answer", {
                        params: {
                            question: state.question,
                            cohort: state.cohort,
                            tenantId: state.tenantId
                        }
                    }
                );

                setState((s) => ({
                    ...s,
                    answer: response.data,
                    isLoading: false,
                }));
            } catch (err) {
                setState((s) => ({ ...s, isLoading: false }));

                const error = err as AxiosError;

                if (error.code && parseInt(error.code, 10) > 499) {
                    console.log("Internal Error");
                }
            }
        },
        [setState, state],
    );

    return (
        <div id="QuestionView">
            <Stack>
                <div className="input ms-depth-16">
                    <Stack
                        horizontal={true}
                        tokens={{
                            childrenGap: 10
                        }}
                    >
                        <div className="cohort">
                            <Dropdown
                                label="Cohort"
                                multiSelect={false}
                                onChange={cohortChange}
                                options={options}
                                selectedKey={state.cohort}
                            />
                        </div>
                        <div className="tenant">
                            <TenantSelector
                                onTenantSelected={selectTenant}
                            />
                        </div>
                    </Stack>
                    <div className="question">
                        <TextField
                            label="Question"
                            multiline={true}
                            onChange={questionChange}
                            resizable={false}
                            value={state.question}
                        />
                    </div>
                    <div className="button">
                        <PrimaryButton onClick={submitQuestion}>
                            Submit
                        </PrimaryButton>
                    </div>
                </div>
                <div className="output">
                    {state.isLoading && <Spinner />}
                    {!state.isLoading && state.answer && (
                        <div className="answer">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Text style={{ fontWeight: 600 }}>
                                                Question
                                            </Text>
                                        </td>
                                        <td>
                                            <Text>
                                                {state.answer.question}
                                            </Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Text style={{ fontWeight: 600 }}>
                                                Identified keywords
                                            </Text>
                                        </td>
                                        <td>
                                            <Text>
                                                {state.answer.identifiedKeywords.join(", ")}
                                            </Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Text style={{ fontWeight: 600 }}>
                                                Expanded set
                                            </Text>
                                        </td>
                                        <td>
                                            <Text>
                                                {state.answer.expandedSet.join(", ")}
                                            </Text>
                                        </td>
                                    </tr>
                                    <tr className="mini-header">
                                        <td colSpan={2}>
                                            <Text style={{ fontWeight: 600 }}>
                                                Experts
                                            </Text>
                                        </td>
                                    </tr>
                                    {state.answer.experts.length === 0 && (
                                        <tr>
                                            <td colSpan={2}>
                                                <Text>No items found</Text>
                                            </td>
                                        </tr>
                                    )}
                                    {state.answer.experts.map(expert => (<>
                                        <tr key={`expert${expert.systemId}`}>
                                            <td>
                                                <Text>{expert.name}</Text>
                                            </td>
                                            <td>
                                                <Text>{Math.round(expert.score * 100) / 100}</Text>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {
                                                    expert.memberships
                                                        .map(m => `${m.name} (${getTypeName(m.type)})`)
                                                        .join(", ")
                                                }
                                                <br />
                                            </td>
                                        </tr>
                                    </>))}
                                    <tr className="mini-header">
                                        <td colSpan={2}>
                                            <Text style={{ fontWeight: 600 }}>
                                                Documents
                                            </Text>
                                        </td>
                                    </tr>
                                    {state.answer.documents.length === 0 && (
                                        <tr>
                                            <td colSpan={2}>
                                                <Text>No items found</Text>
                                            </td>
                                        </tr>
                                    )}
                                    {state.answer.documents.map(document => (
                                        <tr key={`document${document.systemId}`}>
                                            <td>
                                                <Text>{document.name}</Text>
                                            </td>
                                            <td>
                                                <Text>{Math.round(document.score * 100) / 100}</Text>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </Stack>
        </div>
    );
};

export default QuestionView;
