import { DetailsList, DetailsRow, IColumn, Icon, IDetailsRowProps, IRenderFunction, PrimaryButton, SelectionMode, Slider, Spinner, SpinnerSize, Stack, Text, TextField } from "@fluentui/react";
import Axios, { AxiosError } from "axios";
import React, { FunctionComponent, useCallback, useMemo, useState } from "react";

import { TenantSelector } from "src/components";

interface IState {
    tenantId: number | undefined;
    titleBudget: number;
    tagBudget: number;
    headingBudget: number;
    paragraphBudget: number;
    depthDampening: number;
    normalizedValue: string;
    isLoading: boolean;
    terms?: Banshee.IUserScoring[],
    expandedUser?: number;
}

const getTypeName = (type: number) => {
    switch (type) {
        case 9:
            return "Content Page";
        case 10:
            return "News Article";
        case 16:
            return "Wiki Article";
        default:
            return "Unknown";
    }
}

const ExpertScoringView: FunctionComponent = () => {
    const [state, setState] = useState<IState>({
        tenantId: undefined,
        titleBudget: 30,
        tagBudget: 20,
        headingBudget: 15,
        paragraphBudget: 20,
        depthDampening: 80,
        normalizedValue: "",
        isLoading: false,
    });

    const selectTenant = useCallback(
        (tenant: Banshee.ITenant | undefined) => {
            console.log(tenant);

            setState((s) => ({
                ...s,
                tenantId: tenant?.id,
            }));
        },
        [setState],
    );

    const { isLoading } = state;
    const onSubmit = useCallback(
        async () => {
            if (state.isLoading) {
                return;
            }

            setState((s) => ({
                ...s,
                isLoading: true,
            }));

            if (!state?.normalizedValue) {
                setState((s) => ({ ...s, isLoading: false}));
                return;
            }

            const params: any = { ...state };
            params.normalizedValue = params.normalizedValue.toLowerCase();

            if (isNaN(params.tenantId)) {
                delete params.tenantId;
            }

            delete params.isLoading;
            delete params.terms;
            params.depthDampening /= 100;

            try {
                const response = await Axios.get<Banshee.IUserScoring[]>(
                    "/api/v1/experts", {
                        params,
                        headers: {
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        },
                    },
                );

                setState((s) =>({
                    ...s,
                    terms: response.data,
                    isLoading: false,
                }));
            } catch (err) {
                const axiosErr = err as AxiosError;

                setState((s) =>({
                    ...s,
                    terms: [],
                    isLoading: false,
                }));

                const code = parseInt(axiosErr.code || "0", 10);
                if (code > 499) {
                    alert("Internal Server Error!");
                }
            }
        },
        [setState, state],
    )

    const onTitleBudgetChange = useCallback(
        (value) => setState((s) => ({ ...s, titleBudget: value })),
        [setState],
    );

    const onTagBudgetChange = useCallback(
        (value) => setState((s) => ({ ...s, tagBudget: value })),
        [setState],
    );

    const onHeadingBudgetChange = useCallback(
        (value) => setState((s) => ({ ...s, headingBudget: value })),
        [setState],
    );

    const onParagraphBudgetChange = useCallback(
        (value) => setState((s) => ({ ...s, paragraphBudget: value })),
        [setState],
    );

    const onDepthDampeningChange = useCallback(
        (value) => setState((s) => ({ ...s, depthDampening: value })),
        [setState],
    );

    const onNormalizedValueChange = useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { value } = event.target as HTMLInputElement;

            setState((s) => ({ ...s, normalizedValue: value }));
        },
        [setState],
    );

    const expandedUser = state.expandedUser || 0;
    const onRenderNameColumn = useCallback(
        (item: any) => (
            <div className="term-cell">
                <Icon iconName={
                    item.id === expandedUser 
                        ? "ChevronUp"
                        : "ChevronDown"
                 } />
                <Text>{item.name}</Text>
            </div>
        ),
        [expandedUser],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const terms = state.terms || [];

    const renderRow: IRenderFunction<IDetailsRowProps> = useCallback(
        (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => JSX.Element | null): JSX.Element | null => {
            if (!props) {
                return null;
            }

            return (
                <div className="custom-row">
                    <div onClick={() => setState((s) => ({ ...s, expandedUser: props.item.id}))}>
                        <DetailsRow
                            {...props}
                        />
                    </div>
                    {props.item.id === expandedUser && (
                        <div className="breakdown-section">
                            <div className="score-breakdown">
                                <div className="breakdown-row">
                                    <div className="cell name">
                                        <Text>Titles</Text>
                                    </div>
                                    <div className="cell value">
                                        <Text>{Math.round(props.item?.score.titles)}</Text>
                                    </div>
                                </div>
                                <div className="breakdown-row">
                                    <div className="cell name">
                                        <Text>Tags</Text>
                                    </div>
                                    <div className="cell value">
                                        <Text>{Math.round(props.item?.score.tags)}</Text>
                                    </div>
                                </div>
                                <div className="breakdown-row">
                                    <div className="cell name">
                                        <Text>Headings</Text>
                                    </div>
                                    <div className="cell value">
                                        <Text>{Math.round(props.item?.score.headings)}</Text>
                                    </div>
                                </div>
                                <div className="breakdown-row">
                                    <div className="cell name">
                                        <Text>Paragraphs</Text>
                                    </div>
                                    <div className="cell value">
                                        <Text>{Math.round(props.item?.score.paragraphs)}</Text>
                                    </div>
                                </div>
                            </div>
                            <div className="source-breakdown">
                                {(props.item?.occurances || []).map((occ: Banshee.ISourceOccurance) => (
                                    <div className="breakdown-row">
                                        <div className="cell name">
                                            <Text>({getTypeName(occ.type)})</Text>
                                            &nbsp;
                                            <Text>{occ.name}</Text>
                                        </div>
                                        <div className="cell value">
                                            <Text>{Math.round(occ.score)}</Text>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            );
        },
        [expandedUser],
    );

    const columns: IColumn[] = useMemo(
        () => [{
            key: "Name",
            minWidth: 100,
            name: "Name",
            onRender: onRenderNameColumn,
        }, {
            key: "Score",
            minWidth: 100,
            name: "Score",
            onRender: (item: any) => Math.round(item?.score.sum)
        }, {
            key: "Department",
            minWidth: 100,
            name: "Department",
            onRender: (item: any) => item.department?.name,
        }, {
            key: "Office",
            minWidth: 100,
            name: "Office",
            onRender: (item: any) => item.office?.name,
        }, {
            key: "Roles",
            minWidth: 100,
            name: "Roles",
            onRender: (item: any) => (item.roles || [])
                .map((r: any) => r.name)
                .join(", ")
        }],
        [onRenderNameColumn],
    );

    return (
        <div id="ExpertScoringView">
            <h1>Expert Scoring</h1>
            <Stack horizontal={true}>
                <div className="controls ms-depth-16">
                    <TenantSelector onTenantSelected={selectTenant} />
                    <div className="sliders">
                        <Slider
                            label="Title budget"
                            max={100}
                            min={0}
                            onChange={onTitleBudgetChange}
                            value={state.titleBudget}
                            showValue={true}
                        />
                        <Slider
                            label="Tags budget"
                            max={100}
                            min={0}
                            onChange={onTagBudgetChange}
                            value={state.tagBudget}
                            showValue={true}
                        />
                        <Slider
                            label="Heading budget"
                            max={100}
                            min={0}
                            value={state.headingBudget}
                            onChange={onHeadingBudgetChange}
                            showValue={true}
                        />
                        <Slider
                            label="Paragraph budget"
                            max={100}
                            min={0}
                            value={state.paragraphBudget}
                            onChange={onParagraphBudgetChange}
                            showValue={true}
                        />
                        <Slider
                            label="Depth Dampening"
                            max={100}
                            min={0}
                            value={state.depthDampening}
                            onChange={onDepthDampeningChange}
                            showValue={true}
                            valueFormat={(value) => `${value}%`}
                        />
                    </div>
                </div>
                <div className="result">
                    <Stack>
                        <div className="input">
                            <Stack
                                horizontal={true}
                                verticalAlign="end"
                                tokens={{
                                    childrenGap: 10
                                }}
                            >
                                <TextField
                                    name="normalizedValue"
                                    label="Normalized Term"
                                    ariaLabel="Enter a term"
                                    onChange={onNormalizedValueChange}
                                    value={state.normalizedValue}
                                />
                                <PrimaryButton
                                    onClick={onSubmit}
                                >
                                    Run
                                </PrimaryButton>
                            </Stack>
                        </div>
                        <div className="output">
                            {isLoading && <Spinner size={SpinnerSize.large} />}
                            {!isLoading && terms.length > 0 && (
                                <DetailsList
                                    columns={columns}
                                    items={terms}
                                    selectionMode={SelectionMode.none}
                                    onRenderRow={renderRow}
                                />
                            )}
                            {!isLoading && terms.length === 0 && (
                                <Text>No entries to display...</Text>
                            )}
                        </div>
                    </Stack>
                </div>
            </Stack>
        </div>
    );
};

export default ExpertScoringView;
