import {
    DetailsList,
    DetailsRow,
    IColumn,
    Icon,
    IDetailsRowProps,
    IRenderFunction,
    PrimaryButton,
    SelectionMode,
    Slider,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TextField
} from "@fluentui/react";
import Axios, { AxiosError } from "axios";
import React, { FunctionComponent, useCallback, useMemo, useState } from "react";

// import { TenantSelector } from "src/components";

interface IState {
    tenantId: number | undefined;
    titleBudget: number;
    tagBudget: number;
    headingBudget: number;
    paragraphBudget: number;
    depthDampening: number;
    name: string;
    isLoading: boolean;
    terms?: ITermScoring[],
    expandedTerm?: string;
}

interface IScoreBreakdown {
    sum: number;
    titles: number;
    tags: number;
    headings: number;
    paragraphs: number;
}

interface ITermOccurance {
    name: string;
    type: number;
    score: number;
}

interface ITermScoring {
    normalizedValue: string;
    score: IScoreBreakdown;
    occurances: ITermOccurance[];
}

const onRenderScoreColumn = (item: any) => (
    Math.round(item.score.sum)
);

const getTypeName = (type: number) => {
    switch (type) {
        case 9:
            return "Content Page";
        case 10:
            return "News Article";
        case 16:
            return "Wiki Article";
        default:
            return "Unknown";
    }
}

const WeightTestingView: FunctionComponent = () => {
    const [state, setState] = useState<IState>({
        tenantId: undefined,
        titleBudget: 30,
        tagBudget: 20,
        headingBudget: 15,
        paragraphBudget: 20,
        depthDampening: 80,
        name: "",
        isLoading: false,
    });

    /*
    const selectTenant = useCallback(
        (tenant: Banshee.ITenant | undefined) => {
            setState((s) => ({
                ...s,
                tenantId: tenant?.id,
            }));
        },
        [setState],
    );
    */

    const { isLoading } = state;
    const onSubmit = useCallback(
        async () => {
            if (state.isLoading) {
                return;
            }

            setState((s) => ({
                ...s,
                isLoading: true,
            }));

            if (!state?.name) {
                setState((s) => ({ ...s, isLoading: false}));
                return;
            }

            const params: any = { ...state };
            delete params.tenantId;
            delete params.isLoading;
            delete params.terms;
            params.depthDampening /= 100;

            try {
                const response = await Axios.get<ITermScoring[]>(
                    "/api/v1/scores", {
                        params,
                        headers: {
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        },
                    },
                );

                setState((s) =>({
                    ...s,
                    terms: response.data,
                    isLoading: false,
                }));
            } catch (err) {
                const axiosErr = err as AxiosError;

                setState((s) =>({
                    ...s,
                    terms: [],
                    isLoading: false,
                }));

                if (axiosErr.code && parseInt(axiosErr.code, 10) > 499) {
                    alert("Internal Server Error!");
                }
            }
        },
        [setState, state],
    )

    const onTitleBudgetChange = useCallback(
        (value) => setState((s) => ({ ...s, titleBudget: value })),
        [setState],
    );

    const onTagBudgetChange = useCallback(
        (value) => setState((s) => ({ ...s, tagBudget: value })),
        [setState],
    );

    const onHeadingBudgetChange = useCallback(
        (value) => setState((s) => ({ ...s, headingBudget: value })),
        [setState],
    );

    const onParagraphBudgetChange = useCallback(
        (value) => setState((s) => ({ ...s, paragraphBudget: value })),
        [setState],
    );

    const onDepthDampeningChange = useCallback(
        (value) => setState((s) => ({ ...s, depthDampening: value })),
        [setState],
    );

    const onNameChange = useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { value } = event.target as HTMLInputElement;

            setState((s) => ({ ...s, name: value }));
        },
        [setState],
    );

    const expandedTerm = state.expandedTerm || "";

    const onRenderTermColumn = useCallback(
        (item: any) => (
            <div className="term-cell">
                <Icon iconName={
                    item.normalizedValue === expandedTerm 
                        ? "ChevronUp"
                        : "ChevronDown"
                 } />
                <Text>{item.normalizedValue}</Text>
            </div>
        ),
        [expandedTerm],
    );

    const terms = state.terms || [];

    const renderRow: IRenderFunction<IDetailsRowProps> = useCallback(
        (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => JSX.Element | null): JSX.Element | null => {
            if (!props) {
                return null;
            }

            return (
                <div className="custom-row">
                    <div onClick={() => setState((s) => ({ ...s, expandedTerm: props.item.normalizedValue}))}>
                        <DetailsRow
                            {...props}
                        />
                    </div>
                    {props.item.normalizedValue === expandedTerm && (
                        <div className="breakdown-section">
                            <div className="score-breakdown">
                                <div className="breakdown-row">
                                    <div className="cell name">
                                        <Text>Titles</Text>
                                    </div>
                                    <div className="cell value">
                                        <Text>{Math.round(props.item?.score.titles)}</Text>
                                    </div>
                                </div>
                                <div className="breakdown-row">
                                    <div className="cell name">
                                        <Text>Tags</Text>
                                    </div>
                                    <div className="cell value">
                                        <Text>{Math.round(props.item?.score.tags)}</Text>
                                    </div>
                                </div>
                                <div className="breakdown-row">
                                    <div className="cell name">
                                        <Text>Headings</Text>
                                    </div>
                                    <div className="cell value">
                                        <Text>{Math.round(props.item?.score.headings)}</Text>
                                    </div>
                                </div>
                                <div className="breakdown-row">
                                    <div className="cell name">
                                        <Text>Paragraphs</Text>
                                    </div>
                                    <div className="cell value">
                                        <Text>{Math.round(props.item?.score.paragraphs)}</Text>
                                    </div>
                                </div>
                            </div>
                            <div className="source-breakdown">
                                {(props.item?.occurances || []).map((occ: ITermOccurance) => (
                                    <div className="breakdown-row">
                                        <div className="cell name">
                                            <Text>({getTypeName(occ.type)})</Text>
                                            &nbsp;
                                            <Text>{occ.name}</Text>
                                        </div>
                                        <div className="cell value">
                                            <Text>{Math.round(occ.score)}</Text>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            );
        },
        [expandedTerm],
    );

    const columns: IColumn[] = useMemo(
        () => [{
            key: "Term",
            minWidth: 1,
            name: "Term",
            onRender: onRenderTermColumn
        }, {
            key: "Score",
            minWidth: 100,
            name: "Score",
            onRender: onRenderScoreColumn
        }],
        [onRenderTermColumn],
    );

    return (
        <div id="WeightTestingView">
            <h1>Weight Testing</h1>
            <Stack horizontal={true}>
                <div className="controls ms-depth-16">
                    <div className="sliders">
                        <Slider
                            label="Title budget"
                            max={100}
                            min={0}
                            onChange={onTitleBudgetChange}
                            value={state.titleBudget}
                            showValue={true}
                        />
                        <Slider
                            label="Tags budget"
                            max={100}
                            min={0}
                            onChange={onTagBudgetChange}
                            value={state.tagBudget}
                            showValue={true}
                        />
                        <Slider
                            label="Heading budget"
                            max={100}
                            min={0}
                            value={state.headingBudget}
                            onChange={onHeadingBudgetChange}
                            showValue={true}
                        />
                        <Slider
                            label="Paragraph budget"
                            max={100}
                            min={0}
                            value={state.paragraphBudget}
                            onChange={onParagraphBudgetChange}
                            showValue={true}
                        />
                        <Slider
                            label="Depth Dampening"
                            max={100}
                            min={0}
                            value={state.depthDampening}
                            onChange={onDepthDampeningChange}
                            showValue={true}
                            valueFormat={(value) => `${value}%`}
                        />
                    </div>
                </div>
                <div className="result">
                    <Stack>
                        <div className="input">
                            <Stack
                                horizontal={true}
                                verticalAlign="end"
                                tokens={{
                                    childrenGap: 10
                                }}
                            >
                                <TextField
                                    name="name"
                                    label="Name of User/Role/Dept/Office"
                                    ariaLabel="Enter a name"
                                    onChange={onNameChange}
                                    value={state.name}
                                />
                                <PrimaryButton
                                    onClick={onSubmit}
                                >
                                    Run
                                </PrimaryButton>
                            </Stack>
                        </div>
                        <div className="output">
                            {isLoading && <Spinner size={SpinnerSize.large} />}
                            {!isLoading && terms.length > 0 && (
                                <DetailsList
                                    columns={columns}
                                    items={terms}
                                    onRenderRow={renderRow}
                                    selectionMode={SelectionMode.none}
                                />
                            )}
                            {!isLoading && terms.length === 0 && (
                                <Text>No entries to display...</Text>
                            )}
                        </div>
                    </Stack>
                </div>
            </Stack>
        </div>
    );
};

export default WeightTestingView;
